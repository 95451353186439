import * as React from "react"
import { useState } from "react"
import { Frame, addPropertyControls, ControlType } from "framer"

export function PriceCalculatorComponent(props) {
    const [amount, setAmount] = useState("")

    const handleInputChange = (event) => {
        const value = event.target.value
        if (!isNaN(value)) {
            setAmount(value)
        }
    }

    const totalPrice = amount ? parseFloat(amount) * props.pricePerUnit : 0

    return (
        <Frame style={{ padding: props.padding, textAlign: props.textAlign }}>
            <input
                type="text"
                value={amount}
                onChange={handleInputChange}
                placeholder={props.inputPlaceholder}
                style={{
                    marginBottom: props.marginBottom,
                    padding: props.inputPadding,
                    fontSize: props.inputFontSize,
                }}
            />
            <div
                style={{
                    fontSize: props.labelFontSize,
                    fontWeight: props.labelFontWeight,
                }}
            >
                Total Price: ${totalPrice.toFixed(2)}
            </div>
        </Frame>
    )
}

addPropertyControls(PriceCalculatorComponent, {
    pricePerUnit: {
        type: ControlType.Number,
        title: "Price per Unit",
        defaultValue: 89,
    },
    inputPlaceholder: {
        type: ControlType.String,
        title: "Placeholder",
        defaultValue: "Enter amount",
    },
    inputFontSize: {
        type: ControlType.Number,
        title: "Input Font Size",
        defaultValue: 16,
    },
    labelFontSize: {
        type: ControlType.Number,
        title: "Label Font Size",
        defaultValue: 20,
    },
    labelFontWeight: {
        type: ControlType.String,
        title: "Label Font Weight",
        defaultValue: "bold",
    },
    padding: {
        type: ControlType.String,
        title: "Padding",
        defaultValue: "20px",
    },
    textAlign: {
        type: ControlType.Enum,
        title: "Text Align",
        options: ["left", "center", "right"],
        optionTitles: ["Left", "Center", "Right"],
        defaultValue: "center",
    },
    marginBottom: {
        type: ControlType.String,
        title: "Margin Bottom",
        defaultValue: "20px",
    },
    inputPadding: {
        type: ControlType.String,
        title: "Input Padding",
        defaultValue: "10px",
    },
})
